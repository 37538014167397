import { IconButton, MenuItem, Popover, Tooltip, useTheme } from '@mui/material'
import { useUserPreferences } from 'core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { languages } from 'translations'

const Language = ({ ...rest }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const { language, changeLanguage } = useUserPreferences()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectLanguage = (language) => {
    setAnchorEl(null)
    i18n.changeLanguage(language)
    changeLanguage(language)
  }

  const open = Boolean(anchorEl)

  return (
    <div {...rest}>
      <Tooltip title={t('ChangeLanguage')} placement="bottom" style={{ marginRight: 8 }}>
        <IconButton onClick={handleClick}>
          <img src={`/images/flags/${language}.png`} width="24" alt="flag" />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: theme.customShadows.z16,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Object.values(languages).map((rootLanguage, index) => (
          <MenuItem
            key={rootLanguage.localeLanguage}
            style={{ paddingTop: 8, paddingBottom: 8 }}
            onClick={() => handleSelectLanguage(Object.keys(languages)[index])}
          >
            <img
              width="16"
              height="16"
              style={{ marginRight: theme.spacing(1) }}
              src={`/images/flags/${Object.keys(languages)[index]}.png`}
              alt={Object.keys(languages)[index]}
            />
            {rootLanguage.localeLanguage}
          </MenuItem>
        ))}
      </Popover>
    </div>
  )
}

export default Language
