import { Text } from 'react-konva'

export default function DraggableText({ element, handleDragEnd, ...rest }) {
  return (
    <Text
      {...element}
      align="center"
      draggable
      onDragEnd={(e) => handleDragEnd(e, element.id)}
      fillAfterStrokeEnabled={true}
      {...rest}
    />
  )
}
