import { ButtonTriggeredDialog, ButtonTriggeredMenu } from 'components'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { Alert, InputAdornment, MenuItem, TextField } from '@mui/material'
import { API_URL } from 'config'
import { UploadFiles } from 'layouts/Main/components'
import { useSnackbar } from 'notistack'
import { useSingleUpload } from 'utils/hooks'
import { useTenantInfo } from 'core'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { FRAME_FONTS } from 'config'
import { TextDynamicValues } from '../Element/components'

export const INITIAL_TEXT_PROPS = {
  type: 'text',
  text: '',
  x: 50,
  y: 50,
  listening: true,
  fill: '#000',
  stroke: '#008000',
  strokeWidth: 0,
  shadowBlur: 0,
  shadowColor: '#000',
  shadowOpacity: 1,
  fontSize: 24,
  fontStyle: 'normal',
  fontFamily: FRAME_FONTS[0],
  textDecoration: '',
  opacity: 1,
}

export const INITIAL_IMAGE_PROPS = {
  type: 'image',
  x: 0,
  y: 0,
  rotation: 0,
  keepRatio: false,
  listening: true,
}

export default function AddElement({ setElements, stageDimensions }) {
  const { t } = useTranslation('photos')
  const { enqueueSnackbar } = useSnackbar()
  const { activeTenant: tenantId } = useTenantInfo()

  const { handleUpload, open, getInputProps, image, removeFile, setImage } = useSingleUpload({
    tenantId,
    type: 'fullhd',
    format: 'PNG',
  })

  const [newText, setNewText] = useState('') // For adding new text

  const addText = () => {
    if (newText.trim()) {
      const id = uuid()
      setElements((prevElements) => [
        ...prevElements,
        {
          ...INITIAL_TEXT_PROPS,
          id,
          text: newText,
        },
      ])
      setNewText('') // Reset input field
    }
  }

  const addImage = async () => {
    const id = uuid()
    const data = await handleUpload()
    const imageUrl = `${API_URL}/media/${data}`
    const img = new window.Image()
    img.src = imageUrl

    img.onload = () => {
      // Calculate the scaling factors for width and height
      const widthScale = stageDimensions.width / img.width
      const heightScale = stageDimensions.height / img.height

      // Use the smaller scale factor to maintain the aspect ratio
      const scale = Math.min(widthScale, heightScale)

      // Calculate the transformed dimensions
      const transformedWidth = Math.round(img.width * scale)
      const transformedHeight = Math.round(img.height * scale)

      setElements((prevElements) => [
        ...prevElements,
        {
          ...INITIAL_IMAGE_PROPS,
          src: imageUrl,
          id,
          width: transformedWidth,
          height: transformedHeight,
        },
      ])
    }

    img.onerror = () => {
      enqueueSnackbar(t('FailedToLoadImage'), { variant: 'error' })
    }
    setImage(null)
  }

  return (
    <ButtonTriggeredMenu
      buttonText={t('AddComponent')}
      color="primary"
      variant="contained"
      size="small"
      startIcon={<AddIcon />}
    >
      {({ handleClose }) => (
        <span>
          <ButtonTriggeredDialog
            buttonComponent={MenuItem}
            buttonText={t('UploadImage')}
            actionText={t('Upload')}
            handleClick={async () => {
              await addImage()
              handleClose()
            }}
          >
            <UploadFiles
              open={open}
              getInputProps={getInputProps}
              files={image !== null ? [image] : []}
              removeFile={removeFile}
            />
          </ButtonTriggeredDialog>
          <ButtonTriggeredDialog
            buttonComponent={MenuItem}
            buttonText={t('AddText')}
            actionText={t('Add')}
            actionButtonProps={{ disabled: newText.length === 0 }}
            handleClick={() => {
              addText()
              handleClose()
            }}
          >
            <Alert severity="info" sx={{ mb: 1 }}>
              {t('YouCanStyleLater')}
            </Alert>
            <Alert severity="info" sx={{ mb: 1 }}>
              {t('DynamicValuesHint')}
            </Alert>
            <TextField
              autoFocus
              label={t('Text')}
              fullWidth
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextDynamicValues handleUpdate={(type) => setNewText(type)} />
                  </InputAdornment>
                ),
              }}
            />
          </ButtonTriggeredDialog>
        </span>
      )}
    </ButtonTriggeredMenu>
  )
}
