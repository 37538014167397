import { Alert, Box, Divider, Grid, Paper, Typography } from '@mui/material'
import CustomPaperComponent from 'components/CustomPaperComponent'
import { UploadFiles } from 'layouts/Main/components'
import { useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { onDragEnd } from 'utils'
import { useSingleUpload } from 'utils/hooks'
import { AddElement, Element, Preview } from 'views/PhotoFrame/components'
import { v4 as uuid } from 'uuid'
import { INITIAL_IMAGE_PROPS } from 'views/PhotoFrame/components/AddElement/AddElement'
import { enqueueSnackbar } from 'notistack'

export default function UploadAfterGamePhoto({
  initialPhoto,
  stageDimensions,
  setStageDimensions,
  aspectRatio,
  setElements,
  elements,
}) {
  const { t } = useTranslation('photos')
  const { open, getInputProps, image, removeFile } = useSingleUpload({ type: 'photo', instantRevoke: false })

  const addPhoto = async (image) => {
    const id = uuid()
    const img = new window.Image()

    img.src = image.preview ?? image

    img.onload = () => {
      const widthScale = stageDimensions.width / img.width
      const heightScale = stageDimensions.height / img.height

      const scale = Math.min(widthScale, heightScale)

      const transformedWidth = Math.round(img.width * scale)
      const transformedHeight = Math.round(img.height * scale)

      setElements((prevElements) => [
        {
          ...INITIAL_IMAGE_PROPS,
          src: image.preview ?? image,
          id,
          width: transformedWidth,
          height: transformedHeight,
          isDefaultSelected: true,
        },
        ...prevElements,
      ])
    }

    img.onerror = (e) => {
      console.error(e)
      enqueueSnackbar(t('FailedToLoadImage'), { variant: 'error' })
    }
  }

  useEffect(() => {
    if (image) {
      addPhoto(image)
      removeFile()
    }
    // eslint-disable-next-line
  }, [image])

  useEffect(() => {
    if (initialPhoto) {
      addPhoto(initialPhoto)
    }
    // eslint-disable-next-line
  }, [initialPhoto])

  const handleChange = (id, property, value) => {
    setElements((prevElements) =>
      prevElements.map((element) => (element.id === id ? { ...element, [property]: value } : element))
    )
  }

  return (
    stageDimensions &&
    aspectRatio &&
    elements && (
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <CustomPaperComponent sx={{ p: 3 }}>
            <Preview
              isCreate={false}
              aspectRatio={aspectRatio}
              stageDimensions={stageDimensions}
              setStageDimensions={setStageDimensions}
              elements={elements}
              setElements={setElements}
            />
          </CustomPaperComponent>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomPaperComponent sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('UploadPhoto')}
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <UploadFiles
              isTiny
              open={open}
              getInputProps={getInputProps}
              files={image !== null ? [image] : []}
              removeFile={removeFile}
            />
            {image && <Alert severity="info">{t('DeletePhotoHint')}</Alert>}
            {initialPhoto && <Alert severity="info">{t('InitialPhotoUploaded')}</Alert>}
          </CustomPaperComponent>
        </Grid>
        <Grid item xs={12}>
          <CustomPaperComponent sx={{ p: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" gutterBottom>
                {t('StageElements')}
              </Typography>
              <AddElement stageDimensions={stageDimensions} setElements={setElements} />
            </Box>
            <Divider sx={{ mb: 3 }} />
            <DragDropContext onDragEnd={(result) => onDragEnd(result, elements, (quotes) => setElements(quotes))}>
              <Droppable droppableId="list" direction="vertical">
                {(provided) => (
                  <Paper
                    elevation={0}
                    sx={{
                      bgcolor: 'divider',
                      p: !!elements.length ? 1 : 0,
                      pb: !!elements.length ? 0.1 : 0,
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {elements.map((element, index) => (
                      <Element
                        key={element.id}
                        index={index}
                        element={element}
                        setElements={setElements}
                        handleChange={handleChange}
                      />
                    ))}
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </DragDropContext>
            {elements.length === 0 && <Typography>{t('NoElementsAddedYet')}</Typography>}
            <Alert severity="info" sx={{ mb: 1, mt: 1 }}>
              {t('SizesHint')}
            </Alert>
            <Alert severity="info" sx={{ mb: 1 }}>
              {t('LayerOrderHint')}
            </Alert>
            <Alert severity="info">{t('DynamicValuesHint')}</Alert>
          </CustomPaperComponent>
        </Grid>
      </Grid>
    )
  )
}
