import { Box, Card, CardHeader, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useAuth, useTenantInfo, useUserPreferences } from 'core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 2.5, 0),
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  card: {
    background: theme.palette.background.default,
    borderRadius: 12,
  },
}))

const UserInformation = () => {
  const classes = useStyles()
  const { user } = useAuth()
  const { activeTenantFullInfo } = useTenantInfo()
  const { isDrawerOpen } = useUserPreferences()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Card component={RouterLink} to={PageURLs.Profile}>
        <CardHeader
          className={classes.card}
          sx={!isDrawerOpen ? { background: 'transparent !important', px: 0.5 } : {}}
          avatar={
            <ProfileAvatar
              firstName={user?.firstName}
              lastName={user?.lastName}
              className={classes.avatar}
              src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
            />
          }
          title={
            isDrawerOpen && (
              <Typography variant="subtitle2">
                {user?.firstName} {user?.lastName}
              </Typography>
            )
          }
          subheader={isDrawerOpen && t(activeTenantFullInfo?.role)}
        />
      </Card>
    </Box>
  )
}

export default UserInformation
