import { Autocomplete, CircularProgress, debounce, MenuItem, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { API_URL } from 'config'
import { useDate, useEscapeRooms } from 'core'
import { queryParamAsArray } from 'helpers'
import { DateTime } from 'luxon'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ReservationPickerField({
  passedEscapeRoom,
  reservation,
  setReservation,
  textFieldProps = {},
  // DateTime props - { day, week, month, year }
  from = { month: 1 },
  ...rest
}) {
  const { t } = useTranslation('reservations')
  const { activeEscapeRooms } = useEscapeRooms()
  const { localizedDateTimeInMillis } = useDate()

  const escapeRoomIds = useMemo(
    () => (passedEscapeRoom ? [passedEscapeRoom] : activeEscapeRooms.map((escapeRoom) => escapeRoom.id)),
    [activeEscapeRooms, passedEscapeRoom]
  )

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [initialOptions, setInitialOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleOpen = async () => {
    setOpen(true)
    try {
      if (!options.length && inputValue.length === 0) {
        setLoading(true)
        const { data } = await axios.get(
          `${API_URL}/reservation/room/calendar?${queryParamAsArray(
            'roomIds',
            escapeRoomIds
          )}&from=${DateTime.now().minus(from).startOf('day').valueOf()}&to=${DateTime.now()
            .plus({ month: 1 })
            .endOf('day')
            .valueOf()}`
        )
        setLoading(false)

        setOptions(data)
        setInitialOptions(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const debouncedFetch = useMemo(
    () =>
      debounce((query, callback) => {
        fetchReservationsBySearch(query, callback)
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (open && inputValue.length >= 3) {
      if (
        `${localizedDateTimeInMillis(reservation?.bookedDate ?? 0, { dateStyle: 'short', timeStyle: 'short' })}` !==
        inputValue
      ) {
        debouncedFetch(inputValue)
      }
    } else {
      setOptions(
        reservation
          ? [
              reservation,
              ...initialOptions.filter((option) => option.key.reservationId !== reservation.key.reservationId),
            ]
          : initialOptions
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, open, reservation])

  const fetchReservationsBySearch = async (query, callback) => {
    if (query.trim() === '') return // Avoid unnecessary fetch when input is empty
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${API_URL}/reservation/room/search?asc=false&from=0&pageSize=50&${queryParamAsArray(
          'roomIds',
          escapeRoomIds
        )}&searchArg=${query}`
      )

      setOptions((options) => [
        ...options,
        ...data.data.filter((item) => !options.find((opt) => opt.key.reservationId === item.key.reservationId)),
      ])
    } catch (error) {
      console.error('Error fetching search results:', error)
    }
    setLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
    setOptions(initialOptions)
  }

  return (
    <Autocomplete
      fullWidth
      value={reservation}
      onChange={(_, newValue) => {
        setReservation(newValue)
      }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(option, value) => option.key.reservationId === value.key.reservationId}
      getOptionLabel={(option) =>
        `${localizedDateTimeInMillis(option.bookedDate, { dateStyle: 'short', timeStyle: 'short' })}`
      }
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.player.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.player.email.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.player.phone.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.teamName.toLowerCase().includes(inputValue.toLowerCase()) ||
            `${localizedDateTimeInMillis(option.bookedDate, {
              dateStyle: 'short',
              timeStyle: 'short',
            })}`
              .toLowerCase()
              .includes(inputValue.toLowerCase())
        )
      }
      renderOption={(props, option) => (
        <MenuItem {...props} key={props.key}>
          <Stack>
            <Typography variant="body2">
              {localizedDateTimeInMillis(option.bookedDate, { dateStyle: 'short', timeStyle: 'short' })}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {[option.teamName, option.player.name, option.player.email, option.player.phone]
                .filter(Boolean)
                .join(' | ')}
            </Typography>
          </Stack>
        </MenuItem>
      )}
      options={options}
      onInputChange={(_, value) => setInputValue(value)}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Reservation')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          {...textFieldProps}
        />
      )}
      {...rest}
    />
  )
}
