import { Box, Container, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Loading from 'views/Loading'
import { Error } from './components'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

const PageLayout = ({
  title = null,
  shadowTitle = null,
  sx,
  children,
  maxWidth = 'lg',
  isLoading = false,
  isError = false,
  error = null,
  rightComponent,
}) => {
  const classes = useStyles()

  const _isError = isError || !!error

  return (
    <>
      <Helmet titleTemplate="%s | Keyhowl" defaultTitle="Keyhowl">
        <title>{shadowTitle ?? title}</title>
      </Helmet>
      {!isLoading ? (
        !_isError ? (
          <Container maxWidth={maxWidth} className={classes.root} sx={sx}>
            {title && (
              <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.title}>
                <Typography variant="h4">{title}</Typography>
                {rightComponent}
              </Box>
            )}
            {children}
          </Container>
        ) : (
          <Error error={error} />
        )
      ) : (
        <Loading height={`calc(100vh - 150px)`} />
      )}
    </>
  )
}

export default PageLayout
