import { Typography, Box, Divider } from '@mui/material'
import { alpha, onDragEnd } from 'utils'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { API_URL } from 'config'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { File } from './components'

const UploadFiles = ({
  open,
  getInputProps,
  files,
  initialFiles = [],
  setInitialFiles = () => {},
  removeFile,
  removeInitialFiles,
  hideInitialFilesOnChange = false,
  isTiny = false,
  maxFiles,
}) => {
  const { t } = useTranslation(['translation', 'escaperooms'])

  const [hideInitialFiles, setHideInitialFiles] = useState(false)

  useEffect(() => {
    hideInitialFilesOnChange && files.length > 0 && setHideInitialFiles(true)
    // eslint-disable-next-line
  }, [files])

  return (
    <Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(isTiny ? 3 : 5, 1),
          borderRadius: theme.spacing(2),
          border: '1px dashed',
          borderColor: alpha(theme.palette.grey[500], 0.32),
          background: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.background.default,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        })}
        onClick={open}
      >
        <input {...getInputProps()} />
        <div>
          <img src="/illustrations/upload.svg" width={isTiny ? '100' : '180'} alt="upload" />
        </div>
        <div>
          <Typography variant={isTiny ? 'h6' : 'h5'} gutterBottom>
            {t('SelectFile')} {maxFiles && `(${t('MaxFiles', { maxFiles }).toLowerCase()})`}
          </Typography>
          <Typography variant="body2" color="textSecondary" fontSize={isTiny ? '0.75rem' : '0.875rem'}>
            {t('SelectFileDescription')}
          </Typography>
        </div>
      </Box>

      <Box display="flex" alignItems="center" my={2}>
        <Box>
          {!!files.length && (
            <Typography variant="caption" color="textSecondary">
              {t('escaperooms:NewFiles')}:
            </Typography>
          )}
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {files.map((file) => (
              <File key={file.name} imgSrc={file.preview} removeFile={() => removeFile(file.name)} />
            ))}
          </Box>
        </Box>

        {!!files.length && !!initialFiles.length && <Divider flexItem orientation="vertical" sx={{ mr: 1 }} />}

        <Box>
          {!!initialFiles.length && (
            <Typography variant="caption" color="textSecondary">
              {t('escaperooms:ExistingFiles')}
              {initialFiles.length > 1 ? ` ${t('escaperooms:DNDAvailable')}` : ''}:
            </Typography>
          )}
          {!hideInitialFiles && (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, initialFiles, (quotes) => setInitialFiles(quotes))}
            >
              <Droppable droppableId="list" direction="horizontal">
                {(provided) => (
                  <Box display="flex" alignItems="center" ref={provided.innerRef} {...provided.droppableProps}>
                    {initialFiles.map((file, index) => (
                      <Draggable isDragDisabled={initialFiles.length <= 1} key={file} draggableId={file} index={index}>
                        {(provided) => (
                          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <File
                              key={file.name}
                              imgSrc={`${API_URL}/media/${file}`}
                              removeFile={() => removeInitialFiles(file)}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Box>
        {files.length === 0 && initialFiles.length === 0 && (
          <Typography variant="caption" color="textSecondary">
            {t('escaperooms:NoFilesAttached')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default UploadFiles
