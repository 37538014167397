import axios from 'axios'
import { API_URL } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { resizeImage } from 'utils'
import { v4 as uuid } from 'uuid'
import useError from './useError'

const useSingleUpload = ({
  type = 'avatar',
  fileName = null,
  tenantId,
  format = 'JPEG',
  url = `${API_URL}/media`,
  instantRevoke = true,
}) => {
  const { setError } = useError()
  const { enqueueSnackbar } = useSnackbar()
  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    maxSize: 10000000, // 10MB
    onDropRejected: (fileRejections) => {
      fileRejections.map((file) => enqueueSnackbar(file.errors[0].message, { variant: 'error' }))
    },
    onDropAccepted: async (acceptedFiles) => {
      const file =
        type === 'avatar'
          ? await resizeImage(acceptedFiles[0], 300, 300, format)
          : await resizeImage(acceptedFiles[0], 1920, 1080, format)
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setImage(file)
    },
  })

  const futureName = tenantId ? `${tenantId}/${fileName || uuid()}.${format}` : `${fileName || uuid()}.${format}`
  const [image, setImage] = useState(null)
  const [response, setResponse] = useState(null)

  let formData = new FormData()

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    image && instantRevoke && URL.revokeObjectURL(image.preview)
  }, [image, instantRevoke])

  const removeFile = () => {
    setImage(null)
  }

  const handleUpload = async () => {
    formData.append('file', image)
    formData.append('fileName', futureName)

    try {
      const { data } = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setResponse(data)
      return data
    } catch (error) {
      setError(error)
    }
  }

  return { handleUpload, open, getInputProps, image, fileName: response, removeFile, futureName, setImage }
}

export default useSingleUpload
