import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import useSWR from 'swr'
import { API_URL } from 'config'
import { useAuth, useTenantInfo, useUserPreferences } from 'core'
import { FileUploadButton, PocketItem } from './components'
import { TransitionGroup } from 'react-transition-group'
import { DAYS_BEFORE_AUTO_DELETE } from './components/PocketItem/PocketItem'

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  }
}

const Pocket = () => {
  const theme = useTheme()
  const { t } = useTranslation('pocket')
  const { activeTenant: tenantId, activeTenantUsers } = useTenantInfo()
  const { isDrawerOpen } = useUserPreferences()
  const { user } = useAuth()

  const [expanded, setExpanded] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedUsers(typeof value === 'string' ? value.split(',') : value)
  }

  const { data, isLoading, mutate } = useSWR(expanded && `${API_URL}/pocket/tenant/${tenantId}`, {
    fallbackData: [],
    refreshInterval: expanded ? 10000 : undefined,
    keepPreviousData: true,
    revalidateOnFocus: expanded,
  })

  const userIdData = !isLoading
    ? data.reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue.userId]: [
            ...(currentValue.userId in accumulator ? accumulator[currentValue.userId] : []),
            currentValue,
          ],
        }
      }, {})
    : {}

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: { xs: 20, lg: isDrawerOpen ? theme.sizing.sidebar.width + 35 : theme.sizing.sidebar.collapsedWidth + 35 },
        maxWidth: 130,
        boxShadow: theme.customShadows.z8,
        width: '100%',
        zIndex: theme.zIndex.drawer + 1, // so it's above the chat at all times,
        '& .MuiCollapse-root': {
          minWidth: {
            xs: 'calc(100vw - 40px)',
            md: `calc(100vw - ${
              isDrawerOpen ? theme.sizing.sidebar.width + 70 : theme.sizing.sidebar.collapsedWidth + 70
            }px)`,
          },
        },
      }}
    >
      <Accordion
        disableGutters
        sx={{
          boxShadow: theme.customShadows.z16,
          border: 0,
          '&.MuiAccordion-root': {
            borderRadius: '8px 8px 0 0',
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: '8px 8px 0 0',
          },
        }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandLessIcon />}
          id="pocket-header"
          sx={{
            width: 130,
            bgcolor: theme.palette.background.default,
            marginBottom: '-1px',
          }}
        >
          <Typography variant="body2">{t('Pocket')}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            px: 2,
            py: 1,
            bgcolor: 'background.default',
            width: {
              md: `calc(100vw - ${
                isDrawerOpen ? theme.sizing.sidebar.width + 70 : theme.sizing.sidebar.collapsedWidth + 70
              }px)`,
            },
            transition: 'all 0.2s',
            border: '1px solid',
            borderColor: 'divider',
            borderLeft: 0,
            borderTopRightRadius: '8px',
            boxShadow: theme.customShadows.z16,
          }}
        >
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1} mb={1}>
            <FileUploadButton mutate={mutate} />
            <TextField
              select
              SelectProps={{ multiple: true }}
              value={selectedUsers}
              size="small"
              onChange={handleChange}
              label={t('FilterByEmployees')}
              sx={{ maxWidth: 300, width: '100%' }}
            >
              {activeTenantUsers?.map((user) => (
                <MenuItem key={user.id} value={user.id} sx={(theme) => getStyles(user.id, selectedUsers, theme)}>
                  {user?.firstName} {user?.lastName}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Alert severity="info">{t('WhatIsPocket', { daysBeforeDelete: DAYS_BEFORE_AUTO_DELETE })}</Alert>
          {!isLoading ? (
            <TransitionGroup>
              {Object.keys(userIdData)
                .sort((a, b) => {
                  if (+a === user.id) return -1
                  if (+b === user.id) return 1
                  return a - b
                })
                .filter((userId) => (selectedUsers.length ? selectedUsers.includes(+userId) : true))
                .map((userId) => (
                  <Collapse key={userId}>
                    <Stack sx={{ width: '100%' }}>
                      <Divider textAlign="left" sx={{ mt: 1, mb: 0.5 }}>
                        <Chip
                          size="small"
                          label={`${activeTenantUsers?.find((user) => user.id === +userId)?.firstName} 
                          ${activeTenantUsers?.find((user) => user.id === +userId)?.lastName}`}
                        />
                      </Divider>
                      <Grid container spacing={1} component={TransitionGroup}>
                        {userIdData?.[userId]
                          ?.sort((a, b) => b.timeCreated - a.timeCreated)
                          .map((pocket) => (
                            <Grid key={pocket.id} item component={Collapse} orientation="horizontal">
                              <PocketItem pocket={pocket} mutate={mutate} />
                            </Grid>
                          ))}
                      </Grid>
                    </Stack>
                  </Collapse>
                ))}
            </TransitionGroup>
          ) : (
            ''
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default memo(Pocket)
