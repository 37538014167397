import { Palette } from '@mui/icons-material'
import ButtonTriggeredMenu from 'components/ButtonTriggeredMenu'
import { ChromePicker } from 'react-color'

export default function ColorPickerButton({ label, value, onChange, sx, ...rest }) {
  return (
    <ButtonTriggeredMenu
      buttonText={label}
      startIcon={<Palette />}
      variant="contained"
      sx={(theme) => ({
        bgcolor: value,
        color: theme.palette.getContrastText(value),
        ...sx,
      })}
      {...rest}
    >
      {() => <ChromePicker color={value} onChange={({ hex }) => onChange(hex)} />}
    </ButtonTriggeredMenu>
  )
}
